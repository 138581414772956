import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import LoaderAppMin from './core/components/commons/LoaderApp/LoaderAppMin';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { retryImport } from './core/utils/RetryImport';
import { Auth0Provider } from '@auth0/auth0-react';

const App = React.lazy(() => retryImport(() => import('./App')));

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;

function initApp() {
  render();
}

function render() {
  ReactDOM.render(
    <Suspense fallback={<LoaderAppMin />}>
      <Auth0Provider
        domain={domain || ''}
        clientId={clientId || ''}
        authorizationParams={{
          redirect_uri: `${redirect_uri}`,
          prompt: 'login',
          audience: audience,
          responseType: 'token'
        }}
      >
        <App />
      </Auth0Provider>
    </Suspense>,
    document.getElementById('root')
  );
}

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
