import styled, { keyframes } from 'styled-components';
import { ReactComponent as Wheel } from '../../../../assets/images/common/wheel.svg';
import { ReactComponent as SvgTruck } from '../../../../assets/images/common/truck.svg';
import { ReactComponent as SvgCloud } from '../../../../assets/images/common/cloud.svg';
import { ReactComponent as SvgBuilding1 } from '../../../../assets/images/common/building1.svg';
import { ReactComponent as SvgBuilding2 } from '../../../../assets/images/common/building2.svg';
import { ReactComponent as SvgBuilding3 } from '../../../../assets/images/common/building3.svg';
import { ReactComponent as SvgBuilding4 } from '../../../../assets/images/common/building4.svg';

export const SVGAnimationContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const TruckContainer = styled.div`
  width: auto;
  position: relative;
`;

export const Truck = styled(SvgTruck)``;

export const Cloud = styled(SvgCloud)`
  position: absolute;
  bottom: 180px;
  width: 200px;
`;

const truckShake = keyframes`
  0% {
    transform: translateY(1px);
  }
  20% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(2px);
  }
  80% {
    transform: translateY(1px);
  }
  80% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(2px);
  }
 `;

export const TruckSvgContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 40%;
  z-index: 5;
  animation: ${truckShake} infinite 1s linear;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface ITruckWheel {
  width: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const TruckWheel = styled(Wheel)`
  animation: ${rotate} infinite 1.5s linear;
  width: ${(props: ITruckWheel) => (props.width ? `${props.width}px` : `100px`)};
  display: inline-block;
  margin: auto;
  position: absolute;
  top: ${(props: ITruckWheel) => props.top && `${props.top}px`};
  bottom: ${(props: ITruckWheel) => props.bottom && `${props.bottom}px`};
  left: ${(props: ITruckWheel) => props.left && `${props.left}px`};
  right: ${(props: ITruckWheel) => props.right && `${props.right}px`};
  z-index: 2;
`;

export const Road = styled.div`
  width: 100%;
  height: 80px;
  background-color: #3c3939;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
`;

const borderDance = keyframes`
  0% {
    background-position:300px 0px, 0px 116px, 0px 0px, 216px 150px;
}
  100% {
    background-position:  0px 0px, 300px 116px, 0px 150px, 216px 0px;
}
`;

export const RoadLine = styled.div`
  background: linear-gradient(90deg, white 70%, transparent 10%), linear-gradient(90deg, white 50%, transparent 10%),
    linear-gradient(0deg, white 10%, transparent 50%), linear-gradient(0deg, white 10%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 50px 4px, 15px 4px, 4px 50px, 4px 50px;
  background-position: 0px 0px, 200px 100px, 0px 100px, 200px 0px;
  height: 4px;
  width: 100%;
  animation: ${borderDance} 2s infinite linear;
`;

const animateBuilding1 = keyframes`  
0% {
    margin-left: 100%;
}
  100% {
    margin-left: -300%;
}`;

export const BuildingsContainer = styled.div`
  position: absolute;
  left: 100%;
  bottom: 65px;
  opacity: 0.7;
  display: flex;
  overflow-x: hidden;
  animation: ${animateBuilding1} 25s linear infinite;
`;

export const Building1 = styled(SvgBuilding1)`
  height: 150px;
  z-index: 1;
`;

export const Building2 = styled(SvgBuilding2)`
  height: 150px;
  z-index: 1;
`;

export const Building3 = styled(SvgBuilding3)`
  height: 150px;
  z-index: 1;
`;

export const Building4 = styled(SvgBuilding4)`
  height: 150px;
  z-index: 1;
`;

export const SpinnerLoadingPointsStyled = styled.div`
  margin: 25px auto 0;
  width: 80px;
  text-align: center;

  & > div {
    width: 18px;
    height: 18px;
    background-color: #11457d;

    margin-left: 5px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: #50baeb;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: #ff6d00;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
