import React from 'react';

import { SpinnerLoadingPointsStyled } from './styles';

const LoadingPoints = () => {
  return (
    <SpinnerLoadingPointsStyled>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </SpinnerLoadingPointsStyled>
  );
};

export default LoadingPoints;
