import React from 'react';

import logo from '../../../../assets/images/common/PakEnergy_Horiz_LockupsT_Transportation.png';
import LoadingPoints from './LoadingPoints';

const LoaderAppMin = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#fff'
      }}
    >
      <img src={logo} alt="PakEnergy" width={'30%'} />
      <LoadingPoints />
    </div>
  );
};

export default LoaderAppMin;
